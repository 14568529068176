@import url('https://fonts.googleapis.com/css2?family=EB+Garamond:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;700&display=swap');

html {
    height: 100%;
    overflow: hidden !important;
}

:root {
    --safe-area-top: calc(env(safe-area-inset-top) + 8px);
    --safe-area-bottom: env(safe-area-inset-bottom);
}

body {
    -webkit-overflow-scrolling: touch;
    font-family: 'Open Sans', sans-serif;
    overflow: hidden !important;
    padding-top: calc(env(safe-area-inset-top) + 8px);
    padding-bottom: calc(env(safe-area-inset-bottom) + 8px);
}


* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

body {
    background-color:   #E8E5E0;
}

.task-item:hover,
.task-item.active {
    border: 1px solid #1C1D1F;
    border-radius: 8px;
}

.disableHoverFocus {
    pointer-events: none; 
  }

#app-container::-webkit-scrollbar,
.chat-messages-container::-webkit-scrollbar {
    width: 0;
    background: transparent;
}

.message-container {
    width: fit-content;
    min-width: 150px;
    max-width: 70%;
}

.overlay_dialog {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 99999995;
}